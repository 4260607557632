var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('LoaderStyled', [_c('TalpaLoaderWrapper'), _c('GlobalStyle', {
    attrs: {
      "theme": _vm.getTheme
    }
  })], 1) : _c('AppStyled', {
    attrs: {
      "id": "app",
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }, [_c('GlobalStyle', {
    attrs: {
      "theme": _vm.getTheme
    }
  }), _c('TopBar', {
    attrs: {
      "overlayType": _vm.overlayType
    },
    on: {
      "activateOverlay": _vm.activateOverlay
    }
  }), _c('Navigation'), _c('MainStyled', {
    ref: "main",
    attrs: {
      "id": "app-main"
    }
  }, [_c('router-view', {
    attrs: {
      "name": "main",
      "hasScrolled": _vm.mainHasScrolled
    }
  }), _c('FlashMessagesStyled', [_c('flash-message', {
    attrs: {
      "transitionName": "flash"
    }
  })], 1)], 1), _c('Overlay', {
    attrs: {
      "type": _vm.overlayType,
      "args": _vm.overlayArgs,
      "onClose": _vm.overlayOnClose
    },
    on: {
      "closeOverlay": _vm.closeOverlay
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable no-undef */
import '@rschedule/luxon-date-adapter/v2/setup'
import { createProvider } from '@common/apollo'
import { de, en, ru } from '@common/i18n'
import { VueEmotion } from '@egoist/vue-emotion'
import Keycloak from 'keycloak-js'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import VueFlashMessage from 'vue-flash-message'
import VueI18n from 'vue-i18n'
import VueLocalStorage from 'vue-localstorage'
import App from './App.vue'
import createRouter from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueMatomo from 'vue-matomo'
import posthog from 'posthog-js'

import { getUserIdFromToken, getEmailFromToken, getUsernameFromToken } from '@common/utils/src/token'

import PROFILE_QUERY from '#/graphql/profile/show.gql'
import MY_SHIFTPLANS_QUERY from '#/graphql/calendar/myShiftplans.gql'

Vue.config.productionTip = false

Vue.use(VueApollo)
Vue.use(VueI18n)
Vue.use(VueLocalStorage)
Vue.use(VueFlashMessage)
Vue.use(VueEmotion)
Vue.use(VCalendar)
Vue.use(PiniaVuePlugin)

const i18n = new VueI18n({
  messages: {
    en,
    de,
    ru,
  },
  locale: 'de',
})

Vue.filter('capitalize', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('uppercasify', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.toUpperCase()
})

async function loadRequiredData(apolloProvider) {
  try {
    const profile = async () => {
      const res = await apolloProvider?.defaultClient.query({
        query: PROFILE_QUERY,
      })
      return res?.data?.profile
    }
    const myShiftplans = async () => {
      const res = await apolloProvider?.defaultClient.query({
        query: MY_SHIFTPLANS_QUERY,
      })
      return res?.data?.myShiftplans
    }
    const data = await Promise.all([profile(), myShiftplans()])
    return {
      $profile: data[0],
      $myShiftplans: data[1],
    }
  } catch (err) {
    return {
      $profile: {},
      $myShiftplans: [],
    }
  }
}

function containsKeywordInDomain(email, keyword = 'talpa') {
  const regex = new RegExp(`@[^@]*${keyword}`, 'i')
  return regex.test(email) ? 'Talpa' : 'Client'
}

export async function createApp(ssr = false) {
  const keycloakVariables = appConfig?.keycloak
  const url = keycloakVariables?.url ?? ''
  const realm = keycloakVariables?.realm || 'talpa-default'
  const keycloak = new Keycloak({
    url: url + 'auth/',
    realm,
    clientId: keycloakVariables?.clientId || 'vanguard',
  })
  let apolloProvider
  keycloak.onTokenExpired = () => {
    keycloak.updateToken()
  }
  const isAuthenticated = await keycloak.init({
    onLoad: 'login-required',
    enableLogging: process.env.NODE_ENVIRONMENT === 'development',
  })
  apolloProvider = createProvider(ssr, keycloak)
  if (!isAuthenticated) {
    throw new Error('not authenticated')
  }
  Vue.prototype.$keycloak = keycloak
  const pinia = createPinia()
  const router = createRouter(apolloProvider)

  const { $profile, $myShiftplans } = await loadRequiredData(apolloProvider)

  pinia.use(() => ({
    $profile,
    $myShiftplans,
    $router: router,
  }))

  // Matomo analytics - PROD
  const matomoURL = appConfig?.matomo ?? ''
  const userId = getUserIdFromToken(keycloak.token)
  const userEmail = getEmailFromToken(keycloak.token)
  const userName = getUsernameFromToken(keycloak.token)
  const userType = containsKeywordInDomain(userEmail)

  if (matomoURL)
    Vue.use(VueMatomo, {
      host: matomoURL,
      siteId: 1,
      trackerFileName: 'matomo',
      router: router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: true,
      enableHeartBeatTimer: true,
      heartBeatTimerInterval: 15,
      debug: false,
      userId,
      cookieDomain: undefined,
      domains: undefined,
      preInitActions: [['setCustomDimension', 1, userType]],
    })

  // Posthog analytics (Only in FET)
  const posthogToken = appConfig?.posthogKey ?? ''
  if (posthogToken) {
    Vue.prototype.$posthog = posthog.init(posthogToken, {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'identified_only',
      capture_pageview: false,
    })
    posthog.identify(userId, {
      email: userEmail,
      name: userName,
      // Add other user properties here
    })
    router.afterEach(to => {
      posthog.capture('$pageview', {
        $current_url: window.location.href,
        $pathname: to.fullPath,
      })
    })
  }

  const app = new Vue({
    apolloProvider,
    router,
    i18n,
    render: h => h(App),
    pinia,
  })

  return { app }
}

export default {
  navigation: {
    archon: {
      home: 'Home',
      map: 'Karte',
      dashboard: 'Dashboard',
      dashboards: 'Dashboards',
      signals: 'Signale',
      settings: 'Einstellungen',
      reporting: 'Berichte',
      users: 'Benutzer',
      user: 'Benutzer',
      createUser: 'Benutzer erstellen',
      assetDimensions: 'AssetDimensions',
      assetDimension: 'AssetDimension',
      createAsset: 'Asset erstellen',
      assets: 'Maschinen',
      asset: 'Maschine',
      logger: 'Logger',
      loggers: 'Loggers',
      createLogger: 'Logger erstellen',
      geofences: 'Geofences',
      createOrganization: 'Organization erstellen',
      organizations: 'Organisationen',
      organization: 'Organisation',
      subsidiaries: 'Nutzergruppen',
      subsidiary: 'Nutzergruppe',
      createSite: 'Werke erstellen',
      sites: 'Werke',
      site: 'Werk',
      login: 'Login',
      jobs: 'Geplante Jobs',
      events: 'Events',
      wheels: 'Räder',
      wheel: 'Rad',
      tyres: 'Reifen',
      tyre: 'Reifen',
      duplicateUser: 'Benutzer kopieren',
      createTyre: 'Reifen erstellen',
      createWheel: 'Rad erstellen',
      listCalendars: 'Übersicht',
      calendars: 'Kalender',
      calendar: 'Kalender',
      createCalendar: 'Kalender erstellen',
      'heavy-equipment': 'Heavy Equipment',
      job: 'Job',
      createJob: 'Job erstellen',
      assetTypes: 'Maschinen Typen',
      createAssetType: 'Create Asset Type',
      assetUtilizationCategories: 'Utilization Categories',
      createAssetUtilizationCategory: 'Create Utilization Category',
      userPermissionSummary: 'User Permissions',
      ListSchedules: 'Schedules',
      EditSchedule: 'Edit Schedule',
      RescheduleJobs: 'Reschedule Jobs',
      Marketplace: 'Marketplace',
      ListProducts: 'Products',
      EditProduct: 'Product',
      CreateProduct: 'Create Product',
      shiftReports: 'Shift Reports',
      acquisitions: 'Acquisitions',
      createAcquisition: 'Acquisitions',
      acquisition: 'Acquisitions',
      OrganizationAcquisitions: 'Organization Acquisitions',
    },
    cockpit: {
      home: 'Home',
      map: 'Karte',
      dashboard: 'Dashboard',
      dashboards: 'Dashboards',
      signals: 'Signale',
      settings: 'Einstellungen',
      reporting: 'Berichte',
      login: 'Login',
      asset: 'Maschinenprofil',
      organizations: 'Meine Organisationen',
      subsidiary: 'Organisationen',
      assetList: 'Maschinen',
      calendar: 'Planen',
      privacyPolicy: 'Datenschutzrichtlinien',
      AssetOverview: 'Maschinenprofil',
      AssetLog: 'Maschinenprofil',
      maintenance: 'Instandhaltung',
      marketplace: 'Marketplace',
      HealthExplorer: 'Instandhaltung',
      serviceDealer: 'Service',
      ServiceDealerHealthExplorer: 'Service',
      Planning: 'Planning',
      historicalAssetHealth: 'Historischer Maschinenzustand',
      HistoricalAssetHealthList: 'Maschinenzustand',
    },
    subRoutes: {
      AssetHealthOverview: 'Übersicht',
      AssetHealthInsight: 'Insights',
      AssetHealthStatistics: 'Statistik',
      ServiceDealerAssetHealthOverview: 'Übersicht',
      ServiceDealerAssetHealthInsight: 'Insights',
      ServiceDealerAssetHealthStatistics: 'Statistik',
      HistoricalAssetHealthOverview: 'Übersicht',
    },
  },
  actions: {
    create: 'Erstellen',
    share: 'teilen',
    ok: 'ok',
    apply: 'anwenden',
    cancel: 'abbrechen',
    archive: 'archivieren',
    clearAll: 'alle löschen',
    login: 'einloggen',
    logout: 'Abmelden',
    goTo: 'gehe zu',
    hideEmpty: 'leere ausblenden',
    showDetails: 'Details einblenden',
    showActivityColors: 'Aktivitätsfarben einblenden',
    backToOverview: 'zurück zur Übersicht',
    choose: 'auswählen',
    add: 'Hinzufügen',
    delete: 'löschen',
    pressEnterToSelect: 'Auswahl mit Enter bestätigen',
    check_it_out: 'Anleitung',
    learn_more: 'erfahre mehr',
    duplicate: 'duplizieren',
    unsubscribe: 'abmelden',
    scrollToTop: 'nach oben scrollen',
    readMore: 'mehr lesen ...',
    contactUs: 'Kontaktiere uns',
    gotIt: 'Verstanden',
    read: 'Lies sie hier',
    search: 'Suche...',
    selectAll: 'Alle auswählen',
    deselectAll: 'Alle abwählen',
    saveFilters: 'Speichern',
    moreDetails: 'Mehr Details',
    goToInsights: 'Gehe zu Insight | Gehe zu Insights',
    goToOverviews: 'Gehe zu Übersicht',
    goToSignals: 'Gehe zu Signale',
    goToAssetHealthOverview: 'Gehe zu Maschinenzustand - Übersicht',
    showMore: 'Zeige mehr',
    showLess: 'Zeige weniger',
    showSettings: 'Einstellungen anzeigen',
    hideSettings: 'Einstellungen verstecken',
    updateAcquisition: 'Update Acquisition',
    assignProduct: 'Assign Product',
    update: 'Aktualisieren',
    close: 'Schließen',
    copyInformation: 'Informationen kopieren',
    seeRelatedSignals: 'Siehe zugehörige Signale',
  },
  placeholders: {
    searchAssetDimensions: 'Suche nach Dimensionen',
    selectOption: 'gewählte Option',
  },
  messages: {
    unauthorized: 'Entschuldigung, auf diese Seite hast du keine Zugriffsrechte',
    pageNotFound: 'Entschuldigung, die von dir angeforderte Seite gibt es nicht',
    noData: 'Keine Daten zur Darstellung vorhanden. Bitte wenden Sie sich an Ihre Talpa-Kontaktperson.',
    notAvailable: 'Nicht verfügbar',
    noDataForSelectedTime: 'Keine Daten für diesen Zeitraum.',
    noDataWithType: 'Noch keine {type} vorhanden.',
    noNotifications: 'Keine neuen Nachrichten.',
    newDashboard: 'Neues Dashboard',
    errorLoadingOverlay: 'Es ist ein Fehler beim Laden des Overlays aufgetreten.',
    loggingIn: 'Login wird vorbereitet',
    emptyDashboard: 'Dieses Dashboard enthält noch keine Widgets.',
    emptyDashboardAdd: 'Klicken Sie auf die Schaltfläche Werkzeuge, um einige hinzuzufügen.',
    noSignalsSelected: 'Noch keine Signale ausgewählt. Klicke auf den Plus-Button, um welche hinzuzufügen.',
    comingSoon: 'Bald verfügbar!',
    widgetNotCompatible: 'Widget nicht kompatibel mit Dashboard',
    errorLoadingWidgetType: "Fehler beim Laden des Widgets '{type}'.",
    errorLoadingWidget: 'Fehler beim Laden des Widgets.',
    selectOneDay: 'Bitte nur einen Tag auswählen.',
    searchNoResults: 'Keine Treffer bei der Suche nach {query}',
    notUnique: 'Den Eintrag gibt es bereits.',
    required: 'Dieses Feld darf nicht leer sein.',
    minValue: 'Dieses Feld muss mindestens {value} Buchstaben enthalten',
    maxValue: 'Dieses Feld darf nicht mehr als {value} Zahlen enthalten',
    noDimensionsRequired: 'Für dieses Widget muss keine Dimension ausgewählt werden',
    entityCreatedSuccessfully: '{entity} erfolgreich erstellt.',
    entityDeletedSuccessfully: '{entity} erfolgreich gelöscht.',
    entityUpdatedSuccessfully: '{entity} erfolgreich geändert.',
    entityAddedSuccessfully: '{entity} erfolgreich hinzugefügt',
    noAdditionalEntitiesAvailable: 'Keine weiteren {entity} verfügbar.',
    sessionEnded: 'Deine Sitzung ist abgelaufen. Bitte melde Dich erneut an.',
    maxDimensionsAllowed: 'Max. Anzahl erlaubter Dimensionen',
    incompatibleDashboardType: 'Dieses Widget ist nur für Einzelansicht-Dashboards verfügbar',
    hasTooManyDimensions: 'Für die Erstellung dieses Widgets wurde die maximale Anzahl zulässiger Dimensionen überschritten',
    hasTooFewDimensions: 'Für die Erstellung dieses Widgets musst Du weitere Dimensionen auswählen',
    noDimensionsAllowed: 'Für die Erstellung dieses Widgets dürfen keine Dimensionen ausgewählt sein',
    noSubsidiaries: 'Keine Nutzergruppen verfügbar',
    addSubsidiary: 'Nutzergruppe hinzufügen',
    noAdditionalData: 'Keine weiteren Daten',
    tyreNotAvailableTitle: 'Habe deine Reifen im Blick',
    tyreNotAvailableMsg: 'Diese Funktion ist für dich noch nicht verfügbar. Kontaktiere uns für weitere Informationen.',
    new: 'NEU',
    notSupported: 'Dieses Widget wird nicht mehr unterstützt!',
    assetSelector: 'Wählen Sie mindestens eine Maschine aus',
    noSignalData: 'Keine Daten für dieses Signal',
    noDataAvailable: 'Keine Daten vorhanden.',
    noDataShort: 'Keine Daten.',
    clickToInteract: 'Klicken um mit dem Diagramm zu interagieren.',
    minText: 'Feld muss mindestens {value} Buchstaben enthalten.',
    addProduct: 'Assign a product',
    noAcquisition: 'No products assigned yet',
    productsTierNotAvailable:
      'Either the product doesnt have a tier level configured, Or the organization selected have already acquired the highest Tier of the selected product',
    noDimensionData: 'Keine {dimension} Daten für den ausgewählten Zeitraum und/oder die ausgewählten Maschinen',
    dataOutOfRange: 'vor >3 Monate',
    confirmEngineSave: 'Du änderst Motor-Details dieser Maschine. Bist Du dir sicher, dass Du diese Änderung speichern möchtest?',
    confirmCancel: 'Du änderst Motor-Details dieser Maschine. Bist Du dir sicher, dass Du diese Änderung abbrechen möchtest?',
    noSpareParts: 'Keine Ersatzteile verfügbar!',
    maintenanceCopySuccess: 'Servicedetails erfolgreich in die Zwischenablage kopiert!',
    noSickPeriod: 'Für den ausgewählten Monat sind keine Fehlerdetails verfügbar.',
  },
  all: 'alle',
  loadMore: 'Mehr laden',
  backToTop: 'Nach oben',
  noData: 'Keine Daten',
  assetDimension: 'AssetDimension | AssetDimensions',
  alias: 'Alias | Aliase',
  app: 'App | Apps',
  asset: 'Maschine | Maschinen',
  allAssets: 'alle Maschinen',
  assetType: 'Maschinen Typ | Maschinen Typen',
  assetModel: 'Maschinen Model | Maschinen Model',
  bundle: 'Bundle | Bundles',
  loggerModel: 'Logger Model',
  code: 'Code | Codes',
  condition: 'Zustand | Zustände',
  date: 'Datum | Daten',
  designation: 'Bezeichnung | Bezeichnungen',
  category: 'Kategorie | Kategorien',
  utilizationCategory: 'Nutzungsgradkategorie | Nutzungsgradkategorien',
  dimension: 'Dimension | Dimensionen',
  column: 'Spalte | Spalten',
  row: 'Zeile | Zeilen',
  duration: 'Dauer | Dauern',
  email: 'Email | Emails',
  username: 'Benutzername | Benutzernamen',
  role: 'Rolle | Rollen',
  end: 'Ende | Enden',
  geofence: 'Letzter Standort | Geofences',
  icon: 'Icon | Icons',
  language: 'Sprache | Sprachen',
  lastUpdate: 'Letzte Analyse | Letzte Analysen',
  location: 'Standort | Standorte',
  model: 'Modell | Modelle',
  member: 'Mitglied | Mitglieder',
  name: 'Name | Namen',
  now: 'jetzt',
  oem: 'OEM | OEMs',
  organization: 'Organisation | Organisationen',
  overview: 'Übersicht | Übersichten',
  picture: 'Bild | Bilder',
  profile: 'Profil | Profile',
  setting: 'Einstellungen | Einstellungen',
  severity: 'Schwere | Schweren',
  site: 'Werk | Werke',
  unknownSite: 'Unbekanntes Werk | Unbekannte Werke',
  start: 'Start | Starts',
  status: 'Status | Status',
  schedule: 'Zeitplan | Zeitpläne',
  totalMachineHours: 'Motorbetriebsstunden',
  targetValue: 'Zielwert | Zielwerte',
  sum: 'Summe | Summen',
  talpaID: 'TalpaID | TalpaIDs',
  technicalID: ' Technische Platznummer',
  time: 'Zeit | Zeiten',
  title: 'Titel | Titel',
  total: 'Gesamt | Gesamt',
  type: 'Typ | Typen',
  unit: 'Einheit | Einheiten',
  user: 'Benutzer | Benutzer',
  owner: 'Besitzer | Besitzer',
  subsidiary: 'Nutzergruppe | Nutzergruppen',
  widget: 'Widget | Widgets',
  myAccount: 'Mein Account | Meine Accounts',
  myOrganizations: 'Meine Organisation | Meine Organisationen',
  warning: 'Warnung | Warnungen',
  error: 'Störung | Störungen',
  overall: 'Gesamt',
  job: 'Job | Jobs',
  event: 'Event | Events',
  calendar: 'Kalender | Kalender',
  utilizationInShift: 'Auslastung in der Schicht',
  tyre: 'Reifen | Reifen',
  wheel: 'Rad | Räder',
  serialNumber: 'Seriennummer | Seriennummern',
  machineHealth: 'Maschinenzustand',
  health: 'Zustand',
  Health: 'Zustand',
  healthStatus: 'Zustand',
  errorLog: 'Fehlerlog | Fehlerlogs',
  logger: 'Logger',
  loggers: 'Loggers',
  lastLocation: 'Letzter Standort',
  integratedSince: 'Integriert seit',
  manufacturer: 'Hersteller',
  currentLocation: 'Aktueller Standort',
  product: 'Product | Products',
  tier: 'Tier | Tier',
  machineHealthLabels: {
    noFaults: 'Keine Fehler erkannt',
    noWarningsErrors: 'Bei der letzten Aktualisierung des Maschinenstatus lagen keine aktiven Warnungen oder Störungen vor',
    moreCategories: '{x} weitere',
  },
  assetHealthStatus: {
    Green: 'OK',
    Yellow: 'Warnung',
    Red: 'Störung',
    Unknown: 'Nicht verfügbar',
  },
  membershipRoles: {
    user: 'Mitglied',
    admin: 'Administrator',
  },
  itIsYou: 'Das bist Du!',
  warnings: 'Warnungen',
  languages: {
    de: 'Deutsch',
    en: 'English',
    ru: 'Русский',
  },
  uiSettings: {
    units: {
      METRIC: 'metrische Einheiten',
      IMPERIAL: 'imperiale Einheiten (US)',
    },
    time: {
      AM_PM_FORMAT: '12h-Format',
      TWENTYFOUR_FORMAT: '24h-Format',
    },
    defaultApp: {
      MAINTENANCE_APP: 'Instandhaltung App',
      SD_APP: 'Service App',
    },
  },
  types: {
    user: 'Benutzer | Benutzer',
    dashboard: 'Dashboard | Dashboards',
    signal: 'Signal | Signale',
    report: 'Bericht | Berichte',
    kpiCards: 'KPI-Karte | KPI-Karten',
  },
  dashboard: {
    time: {
      calendarWeek: 'KW',
      today: 'Heute',
    },
    errors: {
      titleExists: 'Dashboard Name ist schon vergeben',
      createError: 'Um eine Flottenansicht zu erstellen, wähle bitte mindestens zwei Maschinen aus.',
    },
    types: {
      placeholder: 'Typ',
      placeholderFilter: 'Filter',
      fleet: 'Flottenansicht',
      single_asset: 'Einzelansicht',
      template_fleet: 'Standard Template',
      template_single_asset: 'Template für Einzelansicht',
    },
    filters: {
      NONE: 'Keine',
      MANUAL: 'Manuell',
      ASSET_TYPE: 'Maschinentyp',
      MODEL: 'Modell',
      OEM: 'OEM',
      SITE: 'Werk',
      USER_GROUP: 'Nutzergruppe',
    },
    attributes: {
      date_range: 'Zeitintervall',
      writing_access: 'Schreibzugriff',
      sharedWith: 'Geteilt mit',
      allUsers: 'Alle Nutzer',
      shareDashboardWith: 'Benutzer suchen...',
      noUsersFound: 'Es konnte kein Nutzer gefunden werden',
    },
    shift: 'Schicht | Schichten',
    shiftsView: 'Schichtmodus',
    shiftSelect: 'Bitte wähle ein Schichtsystem',
    shiftsNotAvailable: 'Für die ausgewählten Maschinen ist kein Schichtsystem verfügbar',
    shiftSystem: 'Schichtsystem',
    shiftName: 'Schicht',
    shiftTimes: 'Schichtzeiten',
    shiftStart: 'Schichtbeginn',
    shiftEnd: 'Schichtende',
    dashboardSettings: 'Dashboard-Einstellungen',
    owner: 'Besitzer',
    dashboardSharedMessage: 'Du hast das Dashboard geteilt',
    all: 'Alle',
    sharedByYou: 'von Dir geteilt',
    sharedByOther: 'von anderen geteilt',
    tools: {
      tool: 'Werkzeuge | Werkzeuge',
      addWidget: 'Ein Widget hinzufügen',
      editWidget: 'Widgets bearbeiten',
      copyDashboard: 'Dieses Dashboard duplizieren',
      shareDashboard: 'Dieses Dashboard freigeben',
    },
  },
  assetSignalOverlay: {
    card_name: 'Name der Karte',
  },
  map: {
    assetSelector: {
      placeholder: 'Alle Maschinen',
    },
    legend: 'Legende',
    average: 'ø',
    max: 'Max',
    mapSettings: 'Karteneinstellung',
    geofences: 'Geofences',
    heatmap: 'Heatmap',
    info: 'Bitte wähle zunächst eine Dimension unter dem Menüpunkt Heatmap aus.',
    satelliteStyle: 'Satellitenansicht',
    messages: {
      zoomInForHeatMap: 'Heranzoomen, um die Heatmap freizuschalten',
      generatingHeatMap: 'Generiere Heatmap',
      generatingGeofences: 'Generiere Geofences',
      allMachinesNoLocation: 'Positionsdaten sind nicht verfügbar für die ausgewählte Maschinen',
      someMachinesNoLocation: 'Positionsdaten sind nicht verfügbar für einige ausgewählte Maschinen',
      selectedMachinesNoHeatMap: 'Heatmapdaten sind nicht verfügbar für die ausgewählten Maschinen',
    },
  },
  titles: {
    addDashboard: 'Neues Dashboard erstellen',
    editDashboard: 'Dashboard anpassen',
    shareDashboard: 'Dashboards teilen',
    addWidget: 'Widget hinzufügen',
    editWidget: 'Widget anpassen',
    pleaseConfirm: 'Bitte bestätigen',
    yourOrganizations: 'Deine Unternehmen',
    availableDimensions: 'Verfügbare Dimensionen',
    configureDimensions: 'Dimensionen konfigurieren',
    widgetType: 'Widget-Typ',
    widgetOptions: 'Widget-Optionen',
    widgetPreview: 'Widget-Vorschau',
    showPreview: 'Vorschau anzeigen',
    mostRecent: 'Kürzlich hinzugefügt',
    mostUsed: 'Meist unterstützt',
    sessionEnded: 'Sitzung abgelaufen',
    createAcquisition: 'Create Acquisition',
    addCalendar: 'Create new Calendar',
    editCalendar: 'Edit Calendar',
    addSchedule: 'Create new Schedule',
    editSchedule: 'Edit Schedule',
  },
  createdAt: 'erstellt',
  updatedAt: 'zuletzt geändert',
  runAt: 'ausführen um',
  dashboardType: 'Dashboard Typ',
  noOfAssets: 'Anzahl Maschinen',
  noOfSharedUsers: 'Anzahl an geteilten Nutzern',
  wizard: {
    steps: {
      dimensions: 'Dimensionen',
      widget: 'Visualisierung',
      general: 'Allgemeines',
    },
    dimensions: {
      addDimension: 'Dimension hinzufügen',
      noneAvailable: 'Keine weiteren verfügbar.',
    },
  },
  widgetTypes: {
    TABLE: 'Tabelle',
    MULTI_CHART: 'Multi Graph',
    PIE_CHART: 'Tortendiagramm',
    MAP: 'Karte',
    SANKEY_CHART: 'Sankey-Diagramm',
    TIMELINE_CHART: 'Zeitliniendiagramm',
    ASSET_INFORMATION: 'Maschineninformationen',
    ERROR_LOG: 'Fehlertabelle',
    MULTI_DIMENSION: 'Multi Dimension',
    DRILLING_TABLE: 'Bohrtabelle',
    CYCLE_TABLE: 'Zyklustabelle',
    CARD: 'Kachel',
    HISTOGRAM: 'Histogramm',
    ERROR_STATISTICS: 'Fehlerstatistiken',
    DASHBOARD_INFORMATION: 'Dashboard Information',
    PIVOT_TABLE: 'Pivot-Tabelle',
  },
  graphTypes: {
    placeholder: 'Graph Typ',
    LINE: 'Linie',
    COLUMN: 'Säule',
  },
  selects: {
    asset: 'Maschine auswählen | Maschinen auswählen',
    general: 'Option auswählen',
    dimension: 'Dimension auswählen',
    activities: 'Aktivitäten auswählen',
    type: 'Typ auswählen',
    model: 'Modell auswählen',
    oem: 'OEM auswählen',
    site: 'Werk auswählen',
    organization: 'Select Organization',
    product: 'Select Product',
    productTier: 'Select Product Tier Level',
    acquisition: 'Select Acquisition type',
    usergroup: 'Nutzergruppe auswählen',
  },
  assetTypes: {
    roadheader: 'Teilschnittmaschine',
    scaler: 'Berauber',
    loadhauldump: 'Fahrlader',
    surfacedumper: 'Muldenkipper',
    tractor: 'Traktor',
    fueltanktruck: 'Tankwagen',
    undergrounddumper: 'u.T. Muldenkipper',
    testingtype: 'Testfahrzeug',
    shuttlebus: 'Transportfahrzeug',
    locomotive: 'Lokomotive',
    highwaytruck: 'LKW',
    drillrig: 'Bohrgerät',
    loader: 'Radlader',
    roofbolter: 'Ankerbohrwagen',
    plant: 'Aufbereitungsanlage',
    largeholeauger: 'Großlochbohrwagen',
    dozer: 'Planierraupe',
    continuousminer: 'Vollschnittmaschine',
    bolterminer: 'Bolter Miner',
    excavator: 'Kettenbagger',
    wheelbasedexcavator: 'Bagger bereift',
    ropeexcavator: 'Seilbagger',
    surfacedrillrig: 'Übertage Bohrgerät',
    streetsweeper: 'Kehrmaschine',
    roadroller: 'Road Roller',
    conveyor: 'Conveyor',
    crusher: 'Crusher',
    recycler: 'Recycler',
    compactor: 'Müllverdichter',
    coldmilling: 'Kaltfräse',
    paver: 'Straßenfertiger',
    materialhandler: 'Umschlagmaschine',
    wastehandler: 'Umschlagbagger',
    cleanerloader: 'Reinigungslader',
    compressor: 'Kompressor',
    concretepump: 'Betonpumpe',
    towtractor: 'Schlepper',
    forklift: 'Gabelstapler',
    loadingtransporter: 'Ladetransporter',
    trackconstructiontrain: 'Gleisbauzug',
    highpressurepump: 'Hochdruckpumpe',
    utilitytruck: 'Utility Truck',
    wastedumptruck: 'Müllkipper',
    electrictowingtractor: 'E-Schlepper',
    straddlecarrier: 'Portalhubwagen',
    mobilescreen: 'Mobile-Siebanlage',
    tegelträger: 'Tiegelträger',
    hybridantrieb: 'Hybridantrieb',
    telescopichandler: 'Teleskoplader',
  },
  reporting: {
    report: {
      title: 'FLOTTENBERICHT',
      userName: 'Name',
      organization: 'Organisation',
      site: 'Bergwerk',
      unit: 'Einheit',
      totalAssets: 'Geräteanzahl',
      downloadDate: 'Downloaddatum',
      timeFormat: 'Zeitformat',
      datePicker: 'Zeitauswahl',
      selectedTime: 'Ausgewählter Berichtszeitraum',
      loadingText: 'Bitte warten, Dein Bericht wird erstellt...',
      processing: 'Fortschritt',
    },
    error: {
      downloadError: 'Download fehlgeschlagen: Bitte versuche, den Bericht später erneut herunterzuladen.',
    },
    table: {
      assetDimensions: 'Kenngroesse',
      deviation: 'Abweichung',
    },
    csv: {
      days: 'Tage',
      startDate: 'Start Datum Zeit',
      endDate: 'End Datum Zeit',
    },
    sections: {
      utilization: 'Nutzung',
      production: 'Produktion',
      fuel_consumption: 'Dieselverbrauch',
      errors: 'Fehlermeldungen',
      summary: 'Zusammenfassung',
    },
    stepper: {
      next: 'Nächste',
      back: 'Zurück',
      finish: 'Herunterladen',
      stepOne: {
        title: 'Datum',
        subtitle: 'Berichtszeitraum',
        types: {
          daily: 'Tag',
          weekly: 'Woche',
          monthly: 'Monat',
          yearly: 'Jahr',
          calendarWeek: 'KW',
        },
        disabled: 'Deaktiviert',
      },
      stepTwo: {
        title: 'Kategorien',
        subtitle: 'Berichtsinhalt',
      },
      stepThree: {
        title: 'Herunterladen',
        subtitle: 'Herunterladenstyp',
        selectedRange: ' Ausgewählter Bereich',
        selectedSections: 'Ausgewählte Kategorien',
        downloadReportType: 'Ausgewählter Dateityp des Berichts',
      },
    },
  },
  confirm: {
    delete: {
      Widget: 'Möchtest Du dieses Widget wirklich löschen?',
      Dashboard: 'Möchtest Du dieses Dashboard wirklich löschen?',
      Dashboard_share: 'Willst du dich wirklich von diesem Dashboard abmelden?',
      AssetDimension: "Möchtest Du die AssetDimension '{name}' wirklich löschen?",
      Asset: "Möchtest Du die Maschine '{name}' wirklich löschen?",
      Site: "Möchtest Du das Werk '{name}' wirklich löschen?",
      Organization: "Möchtest Du die Organization '{name}' wirklich löschen?",
      User: "Möchtest Du den Benutzer '{name}' wirklich löschen?",
      Subsidiary: 'Möchtest Du diese Nutzergruppe wirklich löschen?',
      Membership: 'Möchtest Du dieses Mitglied wirklich löschen?',
      Schedule: 'Möchtest Du dieses Eintrag wirklich löschen?',
      ShiftReport: 'Möchtest Du dieses Eintrag wirklich löschen?',
      Job: 'Möchtest Du diesen Job wirklich löschen?',
      signal: {
        card: 'Möchtest Du diese Kachel wirklich löschen?',
      },
      wheelPosition: 'Do you really want to unmount or delete this Asset wheel position?',
      tyreUnmount: 'Do you really want to unmount this tyre?',
      ConfidenitalClient: 'Möchtest Du dieses ConfidentialClient wirklich löschen?',
      Acquisition: 'Do you really want to delete the Acquisition',
      Product: "Do you really want to delete the product '{name}'.",
      AssetWithInSubsidiary:
        "Sie sind gerade dabei, eine Anlage zu löschen, die zu '{name}' gehört. Sind Sie sicher, dass Sie diese löschen möchten?",
    },
    edit: {
      subsidiary: 'Name der Nutzergruppe ändern',
    },
  },
  times: {
    timeAgo: 'vor {count} {type}',
    invalid: 'invalider Zeitstempel',
    millisecond: 'Millisekunde | Millisekunden',
    second: 'Sekunde | Sekunden',
    minute: 'Minute | Minuten',
    hour: 'Stunde | Stunden',
    day: 'Tag | Tage',
    week: 'Woche | Wochen',
    month: 'Monat | Monate',
    year: 'Jahr | Jahre',
    timesAgo: {
      millisecond: 'Millisekunde | Millisekunden',
      second: 'Sekunde | Sekunden',
      minute: 'Minute | Minuten',
      hour: 'Stunde | Stunden',
      day: 'Tag | Tagen',
      week: 'Woche | Wochen',
      month: 'Monat | Monaten',
      year: 'Jahr | Jahren',
    },
  },
  signals: {
    headers: {
      search: 'Verfügbare Signale',
      controls: 'Ausgewählte Signale',
      timelines: 'Zeitauswahl',
      currentSignalValue: 'Aktuelle Signalwerte',
    },
    search: {
      placeholder: 'Hier tippen, um zu filtern.',
    },
    aggregation: {
      label: 'Aggregation',
      placeholder: 'Aggregation auswählen',
      enums: {
        first: 'Erster Wert',
        min: 'Minimum',
        max: 'Maximum',
        avg: 'Durchschnitt',
        count: 'Anzahl',
        sum: 'Summe',
        last: 'Letzter Wert',
      },
    },
    tooltip: {
      name: 'name',
      defaultName: 'Standardname',
      type: 'typ',
      unknown: 'Unbekannt',
      unit: 'Einheit',
      firstDataReceived: 'erste empfangene Daten',
      lastDataReceived: 'letzte empfangene Daten',
    },
  },
  shifts: {
    selectPlaceholder: 'wähle einen Schichtplan',
    errors: {
      interval_error: 'Die minimale Schichtzeit beträgt {minutes} minuten',
    },
  },
  timezone: {
    timezone: 'Zeitzone',
    browser: {
      title: 'Browser-Zeitzone',
      description: 'Die Zeitzone, in der Dein System arbeitet',
    },
    asset: {
      title: 'Maschine-Zeitzone',
      description: 'Die Zeitzone der jeweiligen Maschinen',
    },
    UTC: {
      title: 'UTC-Zeitzone',
      description: 'Die koordinierte Weltzeit',
    },
    selectTimezonePlaceholder: 'Zeitzone wählen',
    browserTime: 'Browser-Zeit',
  },
  activityTypes: {
    WORK: 'In Nutzung',
    IDLE: 'Leerlauf',
    UNLOADING: 'Entladen',
    TRAVELING: 'Fahren',
    TRANSITIONAL_DELAY: 'Umsetzen',
    STANDING: 'Stehen',
    SPOTTING: 'Positionierung',
    QUEUE_BEFORE_LOAD: 'Warten auf Beladung',
    QUEUE_BEFORE_DUMP: 'Warten auf Abkippen',
    MOVING: 'Fahren',
    MANEUVERING: 'Manövrieren',
    MACHINE_UNLOADING: 'Entladen',
    MACHINE_OFF: 'Maschine aus',
    HYDRAULIC_IDLE: 'Leerlauf im Hydraulikbetrieb',
    DIESEL_IDLE: 'Leerlauf Dieselmotor',
    ELECTRIC_IDLE: 'Leerlauf Elektromotor',
    MACHINE_LOADING: 'Laden',
    LOADING: 'Laden',
    IDLE_UNLOADED: 'Leerlauf unbeladen',
    IDLE_LOADED: 'Leerlauf beladen',
    HOLE_SETUP: 'Bohrausleger ausrichten',
    DRIVING_UNLOADED: 'Leerfahrt',
    DRIVING_LOADED: 'Vollfahrt',
    DRILLING: 'Bohren',
    ANCHORING: 'Anker setzen',
    PUSH: 'Schiebearbeit',
    RETURN: 'Rückfahrt',
    WITHDRAWING_BOOM: 'Ausleger einfahren',
    SCALING: 'Berauben',
    ELECTRICAL_SYSTEM_ON: 'Elektrisches System an',
    REPOSITIONING: 'Neupositionierung',
    SUPPORT_LEG_WARNING: 'Warnung Abstellstütze',
    AUXILIARY_WORK: 'Nebenarbeiten',
    PREPARATION_WORK: 'Vorbereitungsarbeiten',
    WAITING: 'Warten auf Laden',
    NO_DATA: 'Keine Daten',
    ROCK_CRUSHING: 'Knäppern',
    ROD_CHANGE: 'Gestängewechsel',
    SWEEPING: 'Kehren',
    ROD_REMOVAL: 'Gestängerückbau',
  },
  activityTable: {
    asset: 'Maschine',
    start: 'Start',
    end: 'Ende',
    type: 'Typ',
    duration: 'Dauer (min)',
    tableIcon: 'Tabellenansicht',
  },
  sankeyChartTable: {
    title: 'Sankey Tabelle',
    sourceGeofence: 'Quelle',
    targetGeofence: 'Ziel',
    materialType: 'Materialtyp',
    noCycles: 'Zyklen',
    tonnage: 'Tonnage',
    sumDistance: 'Fahrstrecke',
    avgDistance: 'Fahrstrecke (∅)',
    sumDuration: 'Dauer',
    avgDuration: 'Dauer (∅)',
    tonnageKilometer: 'Tonnage pro Kilometer',
    fuelPerTonnage: 'Dieselverbrauch pro Tonne',
    fuelPerHour: 'Dieselverbrauch pro Stunde',
    energyConsumptionPerTonnage: 'Netto-Energieverbrauch pro Tonne',
    energyConsumptionPerHour: 'Netto-Energieverbrauch pro Stunde',
    sourceAsset: 'Quell-Maschine',
    targetAsset: 'Ziel-Maschine',
    asset: 'Maschine',
  },
  errorTable: {
    severity: {
      yellow: 'Gelb',
      red: 'Rot',
    },
    recommendedAction: 'Handlungsempfehlung',
    lastDay: 'Letzte aktive 24 Stunden',
    lastHour: 'Letzte aktive Stunde',
    active: 'Ende der letzten Analyse',
    lastWeek: 'Letzten 7 Tage',
  },
  timeScopes: {
    lastHour: 'Letzte Stunde',
    lastSixHours: 'Letzten 6h',
    lastDay: 'Letzten 24h',
    lastWeek: 'Letzte Woche',
  },
  drillingTable: {
    cycle: 'Zyklus-ID',
    holeID: 'Bohrloch-ID',
    start: 'Start',
    asset: 'Maschine',
    drilledDepth: 'Tiefe',
    drilledLength: 'Länge',
    location: 'Position',
    drilledDuration: 'Bohrdauer',
  },
  kpiCard: {
    name: 'Name',
    value: 'Wert',
    unit: 'Einheit',
    signalName: 'Signal',
    lastSeen: 'aktualisiert vor',
  },
  multiDimension: {
    time: 'Entwicklung über Zeit',
    comparison: 'Maschinenvergleich',
  },
  multiChart: {
    warning: 'Es wurden 4 Dimensionen gefunden, das Multi Chart unterstützt maximal 3 Dimensionen.',
  },
  sankeyChart: {
    cycle: 'Zyk.',
  },
  histogramWidgetSettings: {
    histogram: 'Histogramm',
    class: 'Klassen',
    thresholds: 'Grenzwerte',
    default_setting_by_the_system: 'Standardeinstellung',
    set_bin_sizes: 'Wertebereich pro Balken festlegen',
    choose_number_of_bars: 'Anzahl an Balken festlegen',
    type_the_value: 'Hier tippen, um Wert einzugeben',
    max_value: 'Maximalwert',
    min_value: 'Minimalwert',
    error: {
      negativeError: 'Grenzwertdifferenz muss größer als null sein',
      binSizeError: 'Grenzwertdifferenz/Wertebereich pro Balken muss eine ganze Zahl sein',
      noOfBinError: 'Balkenanzahl muss mindestens 3 betragen',
      naNError: 'Ungültige Eingabe. Bitte nur Zahlen eingeben.',
      binNotInteger: 'Ungültige Eingabe. Bitte nur ganze Zahlen eingeben.',
      invalidInput: 'Ungültige Eingabe.',
    },
  },
  nonAdminUsersCanSeeOtherUsers: 'Benutzer ohne Admin-Rechte können andere Benutzer sehen (Benutzernamen und E-Mail-Adressen).',
  dashboardSharingForAllUsers: 'Nutzer können ihre Dashboards teilen',
  errorStatistics: {
    allMachineMessages: 'Alle Fehler',
    red: 'Störungen',
    yellow: 'Warnungen',
    faults: 'Störungen',
    warnings: 'Warnungen',
    errorCount: 'Fehleranzahl',
    errorDuration: 'Fehlerdauer',
    evolutionOverTime: 'Zeitliche Entwicklung',
    machineComparison: 'Maschinenvergleich',
    categoryComparison: 'Kategorievergleich',
    severity: 'Schwere',
  },
  userGuide: {
    title: 'Lerne unsere neue Nutzerverwaltung kennen',
    content: 'Wir stellen Dir die Funktionen der neuen Nutzerverwaltung vor',
  },
  releaseNotes: {
    productName: 'Release notes',
    version: 'version 14.0',
    title: 'Release notes 14.0 ist hier!',
    content: 'Wir stellen Dir die neue Version von GHH inSITE vor',
    learn_more: 'erfahre mehr',
  },
  shiftPopup: {
    title: 'Erstelle einen Schichtplan',
    content:
      'Um einen Schichtplan für Deine aktuelle Maschinenauswahl zu erstellen, kontaktiere bitte Deinen Ansprechpartner bei talpasolutions',
    learn_more: 'mehr info',
  },
  feed: {
    profile: {
      morning: 'Guten Morgen',
      afternoon: 'Guten Tag',
      evening: 'Guten Abend',
      info: 'Sieh Dir hier die neusten Updates an.',
    },
    dashboard: {
      title: 'Meine Dashboards',
      no_dashboards: 'Kein Dashboard verfügbar.',
      create: 'Dashboard erstellen',
      toolTip: 'Dashboardanzahl',
      popover: {
        title: 'Keine Dashboards verfügbar.',
        info: 'Wende Dich an Deinen Administrator, um Zugriff auf Dashboards zu erhalten.',
      },
    },
    asset: {
      title: 'Meine Maschinen',
      getHelp: 'Hilfe',
      noAssets: 'Dir wurden noch keine Maschinen zugewiesen.',
      toolTip: 'Maschinenanzahl',
      search: {
        placeholder: 'Hier tippen, um zu filtern.',
        noAssets: 'Die Maschine die Du suchst, konnte nicht gefunden werd',
      },
      filter: {
        options: {
          all: 'Alle anzeigen',
          warnings: 'Maschinen mit Warnungen',
          errors: 'Maschinen mit Störungen',
        },
      },
      popover: {
        title: 'Keine Maschinen vorhanden.',
        info: 'Kontaktiere Deinen Administrator, um Zugriff auf Deine Maschinen zu erhalten',
      },
    },
    category: {
      all: 'Alle',
      production: 'Produktion',
      maintenance: 'Wartung',
      safety: 'Sicherheit',
      hardware: 'Hardware',
      information: 'Information',
    },
    subsidiaries: 'Nutzergruppen',
    filter: {
      title: 'Filter',
      clear: 'Zurücksetzen',
      options: {
        anytime: 'Alle',
        lastday: 'Letzter Tag',
        lastweek: 'Letzte Woche',
        lastmonth: 'Letzter Monat',
        lastyear: 'Letztes Jahr',
      },
      label: {
        anytime: 'Alle',
        lastday: 'Letzter Tag',
        lastweek: 'Letzte Woche',
        lastmonth: 'Letzter Monat',
        lastyear: 'Letztes Jahr',
      },
    },
    card: {
      name: 'Kachel',
      search: 'Suche...',
      cards: 'Kacheln',
      noData: 'Es sind keine Dimensionen verfügbar',
      title: 'Deine Performance',
      warning: 'Du kannst nur maximal 5 Kacheln gleichzeitig sehen. Um eine neue Kachel hinzuzufügen, lösche eine existierende Kachel.',
      addCard: 'Kachel hinzufügen',
      addCardHere: 'Klicke hier, um eine neue Kachel hinzuzufügen',
      options: {
        lastDay: 'Letzte 24h',
        lastWeek: 'Letzten 7 Tage',
        lastMonth: 'Letzte 30 Tage',
      },
      popover: {
        title: 'Es konnten keine Dimensionen gefunden werden.',
        info: 'Kontaktiere Deinen Administrator, um Zugang zu Dimensionen zu bekommen.',
      },
    },
    remove: 'Löschen',
    delete: 'Hast du den Eintrag ausversehen gelöscht?',
    undo: 'Rückgängig machen',
    getHelp: 'Hilfe',
    feedEndMain: 'Zurück nach oben',
    feedEndInfo: 'Du bist am Ende angekommen.',
    stayTunedInfo:
      'Das ist erst der Anfang! Gespannt, was als nächstes kommt? Bleib dran, um bald mehr operative Insights in diesem Bereich zu entdecken.',
    listItemActions: {
      remove: 'entfernen',
    },
    insightsAvailable: 'Insights sind verfügbar',
    healthStatus: 'Zustand',
  },
  feedItems: {
    SHIFT_REPORT: 'Schichtrapport | Schichtrapporte',
    TYRES_HEALTH_REPORT: `Reifenübersicht deiner Flotte`,
  },
  MachineAssignmentEvents: {
    titles: {
      banner: 'Du hast Zugang zu einer neuen Maschine',
      bannerNoData: 'Bitte kontaktieren Sie einen Talpa-Administrator für weitere Informationen',
      in: 'in',
      of: 'von',
    },
  },
  PerformanceGoalEvents: {
    goalAchieved: 'Dein Performance-Ziel <i>{x}</i> wurde erreicht',
    goalNotAchieved: 'Dein Performance-Ziel <i>{x}</i> wurde nicht erreicht',
    unit: 'unit',
    assetDimension: 'KPI-Name',
    achieved: 'Erreicht',
    targetValue: 'Zielwert',
    change: 'Änderung (%)',
  },
  shiftReportEvent: {
    titles: {
      banner: 'Dieser Schichtrapport basiert auf der letzten Analyse am {date} bei {time}',
      bannerNoData:
        'Die Daten konnten aufgrund fehlender Analysedaten nicht abgerufen werden. Bitte kontaktieren Sie einen Talpa-Administrator für weitere Informationen',
      insights: '{shiftName} Insights -',
      production: 'Produktion',
      drilling: 'Bohren',
      auxiliary: 'Hilfsmaschinen',
      details: 'Details',
    },
    tableHeader: {
      productionAssets: 'Produktionsmaschinen',
      drillingAssets: 'Bohrende Maschinen',
      auxiliaryAssets: 'Hilfsmaschinen',
    },
    shiftGeneralInformation: {
      date: 'Datum',
      reportCreationTime: 'Zeit der Reporterstellung',
      shiftTimePeriod: 'Schichtdauer',
      lastCompleteData: 'Letzte Vollständige Daten',
      noActiveFailure: 'Keine aktiven Fehlermeldungen',
      fuelUsed: 'Dieselverbrauch',
      dataOutOfRange: 'Letzte bekannte Daten vor >7 Tagen',
      endOfShift: 'Ende der Schicht',
    },
    noData: 'Daten noch nicht verfügbar',
  },
  tyresHealthReportEvent: {
    pressureTableHeader: 'Die folgende(n) Maschine(n) haben einen inkorrekten Reifendruck während der letzten Schicht gemeldet',
    temperatureTableHeader: 'Die folgende(n) Maschine(n) haben einen überhitzten Reifen während der letzten Schicht gemeldet',
    bottomLink: {
      text1: 'Aktuelle Informationen der',
      link: 'Maschinen',
      text2: 'nachprüfen',
    },
  },
  plan: {
    showAs: 'Ansicht',
  },
  tyres: {
    name: 'name',
    position: 'position',
    airPressure: 'Luftdruck',
    temperature: 'Temperatur',
    tyreHealth: 'Reifenzustand',
    maintenance: 'Instandhaltung',
    axelPosition: {
      11: 'Vorne links',
      12: 'Vorne rechts',
      21: 'Hinten links außen',
      22: 'Hinten links innen',
      23: 'Hinten rechts innen',
      24: 'Hinten rechts außen',
    },
    toolTip: {
      highTemperature: 'Reifen abkühlen',
      highPressure: 'Druck zu hoch',
      lowPressure: 'Reifenluft nachfüllen',
      noSignal: 'Signal nicht verfügbar',
    },
  },
  privacyPolicy: {
    bannerMessage: 'Wir haben unsere Datenschutzrichtlinien aktualisiert',
    menu: 'Datenschutzrichtlinien',
    accept: 'Lesen und Akzeptieren',
  },
  table: {
    sorting: {
      A_TO_Z: {
        asc: 'Von A bis Z',
        desc: 'Von Z bis A',
      },
      NEW_TO_OLD: {
        asc: 'Neu zu Alt',
        desc: 'Alt zu Neu',
      },
      GOOD_TO_CRITICAL: {
        asc: 'Gut nach kritisch',
        desc: 'Kritisch nach gut',
      },
      OK_TO_ERROR: {
        asc: 'OK zu Störung',
        desc: 'Störung zu OK',
      },
      LOW_TO_HIGH: {
        asc: 'Niedrig zu hoch',
        desc: 'Hoch zu niedrig',
      },
      FRONT_TO_BACK: {
        asc: 'Vorne zu hinten',
        desc: 'Hinten zu vorne',
      },
    },
    filter: {
      successMessage:
        'Ihre Einstellungen sind gespeichert. Gespeicherte Filter und Sortierungen werden bei Ihrer nächsten Sitzung automatisch angewandt. ',
      deleteInfoMessage: 'Möchten Sie Ihre gespeicherten Filter- und Sortiereinstellungen dieser Tabelle wirklich löschen?',
      deleteSuccessMessage: 'Ihre gespeicherten Einstellungen wurden gelöscht! ',
      tooltip: {
        save: 'Filter speichern',
        reset: 'klare Filter',
        delete: 'Löschen Sie bereits gespeicherte Filter',
        close: 'Schließen Sie den Filterbehälter',
        copy: 'Copy results to clipboard',
      },
    },
    copy: {
      successMessage: '{count} rows copied to clipboard',
      errorMessage: 'Error copying rows to clipboard',
    },
  },
  reset: 'zurücksetzen',
  molecules: {
    assetEngine: {
      noEngineData: 'Keine Motordaten verfügbar',
      aggregators: 'MIN/Ø/MAX',
      filling_load_levels: 'FÜLLSTÄNDE/BELADUNGEN',
      temperatures: 'TEMPERATUREN',
      pressures: 'DRÜCKE',
      counters: 'ZÄHLER',
      signals: {
        engine__1_engine_coolant_level_1: 'Motorkühlmittel',
        exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_volume: 'AdBlue',
        exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_level: 'AdBlue',
        engine__1_aftertreatment_1_diesel_particulate_filter_soot_load_percent: 'Rußbeladung',
        engine__1_aftertreatment_1_diesel_particulate_filter_ash_load_percent: 'Aschebeladung',
        engine__1_engine_oil_life_remaining: 'Verbleibende Öllebensdauer ',
        engine__1_engine_oil_level: 'Motoröl',
        engine__1_engine_intake_manifold_1_temperature: 'Krümmer Einlass',
        engine__1_engine_exhaust_temperature: 'Motorabgas 1',
        engine__1_engine_exhaust_temperature_2: 'Motorabgas 2',
        engine__1_engine_coolant_temperature: 'Motorkühlmittel',
        exhaust_emission_controller_aftertreatment_1_diesel_exhaust_fluid_tank_temperature_1: 'AdBlue',
        exhaust_emission_controller_aftertreatment_1_scr_intake_temperature: 'SCR Einlass',
        engine__1_aftertreatment_1_diesel_oxidation_catalyst_intake_temperature: 'Diesel-Oxidationskatalysator Einlass',
        engine__1_aftertreatment_1_diesel_oxidation_catalyst_outlet_temperature: 'Diesel-Oxidationskatalysators Auslass',
        engine__1_ambient_air_temperature: 'Umgebungsluft',
        engine__1_engine_oil_temperature_1: 'Motoröl',
        exhaust_emission_controller_aftertreatment_1_exhaust_temperature_1: 'Abgasnachbehandlung',
        engine__1_engine_intake_manifold__1_pressure: 'Krümmer Einlass',
        engine__1_engine_air_filter_1_differential_pressure: 'Luftfilterdifferenz',
        engine__1_engine_fuel_delivery_pressure: 'Kraftstoffvordruck',
        engine__1_engine_oil_pressure_1: 'Öldruck',
        engine__1_aftertreatment_1_diesel_exhaust_fluid_doser_1_absolute_pressure: 'Abgas-Flüssigkeitsdosierer',
        engine__1_aftertreatment_1_diesel_particulate_filter_outlet_pressure: 'Dieselpartikelfilter Auslass',
        engine__1_aftertreatment_1_diesel_particulate_filter_differential_pressure: 'Dieselpartikelfilter Differenz',
        engine__1_engine_intake_air_pressure: 'Ansaugluft',
        engine__1_engine_oil_filter_differential_pressure: 'Motorölfilterdifferenz',
        engine__1_aftertreatment_1_diesel_oxidation_catalyst_differential_pressure: 'Diesel-Oxidationskatalysators Differenz',
        engine__1_barometric_pressure: 'Umgebungsluft',
        engine__1_engine_total_hours_of_operation: 'Motorbetriebsstunden',
        engine__1_engine_total_fuel_used: 'Kraftstoffverbrauch insgesamt',
        engine__1_aftertreatment_1_diesel_particulate_filter_time_since_last_active_regeneration:
          'Dieselpartikelfilter Zeit seit letzter aktiver Regeneration',
        engine__1_engine_fuel_supply_estimated_remaining_distance: 'Reichweite (Kraftstoff)',
      },
    },
  },
  label: 'Bezeichnung | Bezeichnungen',
  description: 'Beschreibung | Beschreibungen',
  engine: 'Motor',
  timelinechart: {
    relative: 'Relative Ansicht',
    absolute: 'Absolute Ansicht',
    filterEmpty: 'Tage ohne Daten ausblenden',
    showDetails: 'Daten nach Stunden anzeigen',
    tableView: 'Tabellenanasicht',
    chartView: 'Diagrammansicht',
  },
  maintenance: {
    district: 'Revier | Reviere',
    fleetDetails: 'Flottendetails',
    fleetComposition: 'Flottenzusammenstellung',
    fleet: 'Flotte | Flotten',
    shiftPlan: 'Schichtplan | Schichtpläne',
    shift: 'Schicht | Schichten',
    selectFleet: 'Ausgewählte Flotte',
    selectShiftPlan: 'Ausgewählter Schichtplan',
    selectShift: 'Ausgewählte Schicht',
    selectShiftOccurance: 'Ausgewähltes Verschiebungsereignis',
    assetRequiresAttention: 'Maschine mit kritischen Meldungen | Maschinen mit kritischen Meldungen',
    assetWithErrors: 'Maschine mit Störmeldungen | Maschinen mit Störmeldungen',
    assetWithWarnings: 'Maschine mit Warnmeldungen | Maschinen mit Warnmeldungen',
    assetInUnknownCondition: 'Maschine in unbekanntem Zustand | Maschinen in unbekanntem Zustand',
    assetInGoodCondition: 'Maschine in gutem Zustand | Maschinen in gutem Zustand',
    dataCoverage: 'Datenabdeckung',
    priorityError: 'Kritische Meldungen',
    acknowledged: 'Bestätigen',
    fixed: 'behoben',
    context: 'Kontext',
    engineSpeed: 'Motordrehzahl',
    cooleantLevel: 'Kühlflüssigkeitsfüllstand',
    assetHealth: 'Maschinenzustand',
    fuelType: 'Kraftstofftyp',
    hourMeter: 'Stundenzähler',
    risk: 'Risiko',
    insights: 'Insights',
    active: 'Aktiv',
    currentShift: 'Aktuelle Schicht',
    currentDay: 'Aktueller Tag',
    previousShift: 'Vorherige Schicht',
    previousDay: 'Vorheriger Tag',
    last24Hours: 'Letzte 24 Stunden',
    last7Days: 'Letzte 7 Tage',
    last14Days: 'Letzte 14 Tage',
    last21Days: 'Letzte 21 Tage',
    last30Days: 'Letzte 30 Tage',
    component: 'Komponente',
    start: 'Startzeit',
    count: 'Interne Zählung',
    HealthExplorer: 'Instandhaltung',
    summaryByAssetType: 'Zusammenfassung nach Maschinentypen',
    summary: 'Zusammenfassung',
    details: 'Details',
    recommendedAction: 'Handlungsempfehlung',
    insightsBySeverity: 'Insight nach Schwere',
    assetActivity: 'Maschinenaktivitäten',
    insightsByOccurrence: 'Insight nach Ereignis',
    insightsByCount: 'Insight nach Anzahl',
    insightsByDuration: 'Insight nach Dauer',
    badgeText: 'Letzten {x} Tage',
    faultCount: 'Anzahl',
    duration: 'Dauer',
    variation: 'Änderung',
    since: 'seit',
    selectedIssueNotInTimeframe: 'Die ausgewählte Insight ist nicht mehr verfügbar.',
    rawDataForInsight: 'Rohdaten zum Insight',
    area: 'Bereich',
    fmiDescription: 'FMI Beschreibung',
    spareParts: 'Ersatzteil | Ersatzteile',
    engine: {
      make: 'Hersteller',
      unitNumber: 'Bauteilnummer',
      softwareIdentificationFields: 'Anzahl der Softwareidentifikationsfelder',
      softwareIdentification: 'Softwareidentifikation',
      ecu: {
        partNumber: 'ECU Teilenummer',
        location: 'ECU Einbauort',
        manufacturerName: 'ECU Hersteller',
        hardwareVersion: 'ECU Hardware ID',
        constructionDate: 'ECU Herstelldatum',
        serialNumber: 'ECU Seriennummer',
        type: 'ECU Typ',
      },
    },
    serviceAlerts: {
      nextService: 'Nächster Service',
      serviceDate: 'Servicedatum',
      interval: 'Zeit bis zum Service',
      number: 'Nummer',
      quantity: 'Menge',
      service: 'Service',
    },
  },
  severityLevels: {
    high: 'Hoch',
    medium: 'Mittel',
    low: 'Niedrig',
  },
  severityCodes: {
    priority: 'Priorität',
    red: 'Störung',
    yellow: 'Warnung',
    error: 'Störung',
    warning: 'Warnung',
    malfunction: 'Malfunction',
    protection: 'Protection',
    ok: 'Ok',
    nodata: 'Keine Daten',
  },
  notifications: {
    title: 'Benachrichtigungen',
    priorityError: 'hat {count} kritische Meldung | hat {count} kritische Meldungen',
    engineIssue: 'hat {count} Motorrelevante Ereignis | has {count} Motorrelevante Ereignisse',
    productionIssue: 'hat {count} produktionsrelevante Ereignis | hat {count} produktionsrelevante Ereignisse',
    bomagError: 'hat {count} rote Bomag Fehlermeldung | hat {count} rote Bomag Fehlermeldungen',
    overspeedIssue:
      'hat eine Geschwindigkeitsüberschreitung verursacht in {geofence} mit der Geschwindigkeit {speed} für {duration} Sekunden zwischen {startTime} & {endTime} uhr',
    endOfList: 'Das waren alle Benachrichtigungen der letzten 7 Tage!',
    markAll: 'Alle als gelesen markieren',
    subtitle: {
      unread: 'Ungelesen Nachrichten',
      all: 'Alle Nachrichten',
    },
    tooltip: {
      viewUnread: 'Ungelesen Nachrichten anzeigen',
      viewAll: 'Alle Nachrichten anzeigen',
      markRead: 'als gelesen markieren',
      markUnread: 'als ungelesen markieren',
    },
  },
  dashboardInformationSettings: {
    default: 'Kein Logo',
    select: 'Wählen Sie Logo',
  },
  errorStatisticsSettings: {
    select: 'Wählen Sie Filter',
  },
  deleted: {
    assets: 'gelöschte Maschine | gelöschte Maschinen',
  },
  showUnknowns: 'Unbekannte Bereiche eines Zyklus filtern',
  groupBy: 'Gruppiere nach',
  unknownOEM: 'Unbekanntes OEM',
  unknownModel: 'Unbekanntes Modelle',
  unknownType: 'Unknown Typ',
  noFilter: 'Kein Filter aktiv',
  selectedAssets: 'Ausgewählte Maschine | Ausgewählte Maschinen',
  selectedAssetsInfo: 'Alle {x} Maschinen wurden ausgewählt',
  assetNotSelectedInfo: 'Bitte wählen Sie mindestens eine Maschine aus | Bitte wählen Sie mindestens 2 Maschinen aus',
  assetGroupNotSelectedInfo: 'Bitte wählen Sie eine Option',
  pivotTable: {
    sourceGeofence: 'Ladebereich',
    targetGeofence: 'Entladebereich',
    materialType: 'Materialtyp',
    noCycles: 'Zyklen',
    tonnage: 'Tonnage',
    sumDistance: 'Fahrstrecke',
    avgDistance: 'Fahrstrecke (∅)',
    sumDuration: 'Dauer',
    avgDuration: 'Dauer (∅)',
    tonnageKilometer: 'Tonnage pro Kilometer',
    fuelPerTonnage: 'Dieselverbrauch pro Tonne',
    fuelPerHour: 'Dieselverbrauch pro Stunde',
    energyConsumptionPerTonnage: 'Netto-Energieverbrauch pro Tonne',
    energyConsumptionPerHour: 'Netto-Energieverbrauch pro Stunde',
    sourceAsset: 'Lademaschine',
    targetAsset: 'Transportmaschine',
    asset: 'Lade- oder Transportmaschine',
    nonTalpaAsset: 'Keine-Talpa-Maschine',
  },
  advancedSearch: {
    hotkeyExplanations: {
      enter: 'to select',
      arrows: 'to navigate',
      esc: 'to exit search',
    },
  },
  marketplace: {
    productTypes: {
      all: 'Alle',
      app: 'Apps',
      bundle: 'Bundles',
    },
  },
  defaultApp: 'Standard-App',
  validationErrors: {
    required: 'This field is required.',
    requiredIf: 'This field is required.',
    minLength: 'Text should be longer',
  },
  durationFormatSettings: {
    title: 'Zeitraum-Format',
    label: 'Aktivieren Sie das Dezimalformat für zeitbasierte KPIs',
  },
  planning: {
    myCalendars: 'Meine Kalender',
    createCalendar: 'Kalender erstellen',
    addSchedule: 'Zeitplan hinzufügen',
    ownerTypePlaceholder: 'Besitzertyp wählen',
    ownerType: 'Besitzertyp',
    self: 'Selbst',
    ownerPlaceholder: 'Besitzer wählen',
    createSchedule: 'Zeitplan erstellen',
    editSchedule: 'Zeitplan bearbeiten',
    timeSettings: 'Zeiteinstellungen',
    goals: 'Ziele',
    scheduleTypePlaceholder: 'Zeitplantyp wählen',
    scheduleType: 'Zeitplantyp',
    recurringTask: 'Wiederkehrende Aufgabe',
    createShiftReport: 'Schichtbericht erstellen',
    assignedAssets: 'Zugewiesene Maschinen',
    allAssetsOfOwner: 'Alle Maschinen des Besitzers/der Besitzer({x})',
    custom: 'Benutzerdefiniert',
    scheduleStartDate: 'Startdatum festlegen/planen ',
    freqency: 'Häufigkeit',
    daily: 'täglich',
    weekly: 'wöchentlich',
    monthly: 'monatlich',
    yearly: 'jährlich',
    interval: 'Intervall',
    daysInWeek: 'Tage der Woche',
    weekDays: {
      mo: 'Mo',
      tu: 'Di',
      we: 'Mi',
      th: 'Do',
      fr: 'Fr',
      sa: 'Sa',
      su: 'So',
    },
    addGoal: 'Ziel hinzufügen',
    createGoal: 'Neues Leistungsziel erstellen',
    editGoal: 'Leistungsziel bearbeiten',
    assignedAssetsGoal: 'Zugewiesene Maschinen für das Ziel',
    allAssetsOfSchedule: 'Alle Maschinen eines Plans({x})',
    rule: 'Regel | Regeln',
    addRule: 'Regel hinzufügen',
    min: 'Min',
    max: 'Max',
    range: 'Bereich',
    below: 'Unter',
    above: 'Über',
    createRule: 'Regel erstellen',
    updateRule: 'Regel aktualisieren',
    selectAssetDimension: 'Dimension auswählen',
    selectRuleType: 'Regeltyp wählen',
    ruleType: 'Regeltyp',
    threshold: 'Schwellenwert',
    more: '{x} weitere',
    reports: 'Berichte',
    messages: {
      noCalendars: 'Du hast keine Kalender',
      noSchedules: 'No schedules in calendar',
      newCalendarCreated: 'Kalender {x} erfolgreich erstellt',
      addNewSchedule: 'Möchtest du einen Zeitplan dem Kalender hinzufügen?',
      removeGoal: 'Möchtest Du das Ziel <i>{x}</i> aus dem Zeitplan/plan entfernen?',
      removeRule: 'Möchtest Du die Regel für <i>{x}</i> entfernen?',
    },
    timezoneOptions: {
      schedule: 'Schedule',
      local: 'Local',
      utc: 'UTC',
    },
    preview: 'Preview',
    previewMsg: 'Sample cards for {x} previous occurrences of schedule',
  },
  viewCountOfInstance: 'Y-Achse in Anzahl der Instanzen anzeigen',
  count: 'Anzahl',
  cycleTable: {
    cycleID: 'Zyklus-ID',
    start: 'Start',
    end: 'Ende',
    sourceAsset: 'Quell-Maschine',
    targetAssetTalpaID: 'Ziel-Maschine',
    sourceGeofence: 'Quell-Geofence',
    sourceAssetTalpaID: 'Quell-Maschine',
    asset: 'Maschine',
    targetGeofence: 'Ziel-Geofence',
    materialType: 'Materialtyp',
  },
  massflow: 'Massenfluss',
  none: 'Keine',
  activity: 'Aktivität',
  loadmore: 'Mehr laden',
  enginesManager: {
    engine: 'Motor',
    heading: 'Motorenmanager',
    nthEngineHeading: 'Motor {nth} manager',
    serialNumber: {
      label: 'Motor-Seriennummer',
      validations: {
        required: 'Motor-Seriennummer ist erforderlich',
      },
    },
    ecu: {
      partNumber: 'ECU-Teilenummer',
    },
    make: {
      name: 'Motor-Hersteller',
      create: 'Motor-Hersteller erstellen',
    },
    model: {
      name: 'Motor-Modell',
      create: 'Motor-Modell erstellen',
    },
    energy: {
      source: 'Energiequelle',
    },
    addEngine: 'Motor hinzufügen',
    addAnotherEngine: 'Füge einen weiteren Motor hinzu',
    updateEngine: 'Motor aktualisieren',
    saveEngine: 'Motor speichern',
  },
  historicalAssetHealth: {
    title: 'Maschinenzustand',
    mtbf: 'Mittleren Betriebsdauer zwischen Fehlermeldungen',
    mttr: 'Mittlere Zeit zur Fehlerbehebung',
    tooltip: {
      sickPeriods: 'Fehlerdauer',
      errors: 'Fehler | Fehler',
      warnings: 'Warnung | Warnungen',
      healthyPeriod: {
        info: '<strong>Keine Fehler</strong> zeigt an, dass die Maschine ohne die unten aufgeführten Fehler betrieben wird.',
      },
      sickPeriod: {
        info: `<strong>Hat Fehler</strong> zeigt die Betriebszeit der Maschine mit ausgelösten Motorfehlern in Kombination mit den FMI-Codes 0, 1, 16 oder 18.`,
      },
      unknownPeriod: { info: '<strong>Keine Information</strong> weisen auf Datenunverfügbarkeit hin.' },
      mttbs: 'MTBS (monatlich) = Betriebszeit / Gesamtdauer der Fehlerperioden',
      mttrs: 'MTTRS (monatlich) = Gesamtdauer der Fehlerperioden / Anzahl der Fehlerperioden',
    },
    sickPeriods: {
      tooltip: {
        duration: 'Dauer: {duration}',
      },
    },
    status: {
      healthy: 'Keine Fehler',
      sick: 'Hat Fehler',
      unknown: 'Keine Information',
    },
    sickPeriod: 'Fehlerdauer',
    errorCode: 'Fehlercode # {errorCode}',
    operationTime: 'Betriebszeit',
    sickHours: 'Fehlerdauer',
  },
  unknownUsergroup: 'Unbekannte Nutzergruppe',
  shortMinute: 'Min',
}
